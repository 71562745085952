<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Accounts')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          v-if="!isClient"
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses,
            types: types
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="openCloseFilters"
      />

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="accounts"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="accountsLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                auto-width
              >
                {{ props.row.id }}
              </q-td>

              <q-td
                key="name"
                :props="props"
                auto-width
              >
                <q-badge
                  :color="stateColors[props.row.state]"
                  class="q-mr-sm"
                  rounded
                />

                {{ props.row.name }}
              </q-td>

              <q-td
                key="transaction"
                :props="props"
                auto-width
              >
                <q-btn
                    v-if="isSupervisior || isAdministrator"
                    size="sm"
                    color="green-6"
                    icon="add"
                    :label="this.$t('Add transaction')"
                    unelevated
                    @click="handleAddTransaction(props.row)"
                />
              </q-td>

              <q-td
                key="type"
                :props="props"
                auto-width
              >
                <q-badge
                  :color="typeColors[props.row.type]"
                  :label="props.row.type"
                  class="q-px-sm q-py-xs"
                  rounded
                />
              </q-td>

              <q-td
                key="balance"
                :props="props"
                auto-width
                :class="props.row.balance < 0 ? 'text-red-7' : 'text-green-14'"
              >
                {{ props.row.balance }}
              </q-td>

              <q-td
                key="owner"
                :props="props"
                auto-width
              >
                <span v-if="props.row._embedded.owner">
                  {{ `${props.row._embedded.owner.name} (${props.row._embedded.owner.id})` }}
                </span>
              </q-td>

              <q-td
                key="customer"
                :props="props"
                auto-width
              >
                <span v-if="props.row._embedded.customer">
                  {{ `${props.row._embedded.customer.name} (${props.row._embedded.customer.id})` }}
                </span>
              </q-td>

              <q-td
                key="contractor"
                :props="props"
                auto-width
              >
                <span v-if="props.row._embedded.contractor">
                  {{ `${props.row._embedded.contractor.name} (${props.row._embedded.contractor.id})` }}
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
                auto-width
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <q-dialog v-model="accountModal">
      <q-card>
        <q-card-section>
          <account
            @close="handleAccountModal"
            @save="handleAccount"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
    <transaction-modal ref="transactionModal" @submit="refreshAccount" />
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import Search from '../../components/search/Search.vue'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Account from '../../components/account/Account.vue'
import TransactionModal from "./../../components/modals/TransactionModal.vue";

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../../components/global/TableMixin'

export default {
  name: 'AccountsTable',
  emits: ['account'],
  components: {
    TransactionModal,
    Search,
    FilterCollapse,
    Account
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      accountModal: false,
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: '',
          name: 'transaction',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Balance'),
          name: 'balance',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Customer'),
          name: 'customer',
          align: 'left'
        },
        {
          label: this.$t('Contractor'),
          name: 'contractor',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        }
      ],
      typeColors: {
        deposit: 'teal-6',
        payment: 'amber-5 text-dark'
      },
      stateColors: {
        active: 'active',
        frozen: 'grey',
        inactive: 'inactive'
      },
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'frozen', title: 'Frozen' },
        { id: 'inactive', title: 'Inactive' }
      ],
      types: [
        { id: 'deposit', title: 'Deposit' },
        { id: 'payment', title: 'Payment' }
      ],
      activatedFields: [
        'id',
        'state',
        'created.from',
        'created.to',
        'name',
        'owner',
        'type',
        'balance.from',
        'balance.to'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'accounts',
      'totalAccountsNumber',
      'accountsLoading',
      'appOptions',
      'isClient',
      'isSupervisior',
      'isAdministrator'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ') + this.totalAccountsNumber
        : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapMutations([
      'upsertAccount'
    ]),
    ...mapActions([
      'loadAccounts'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    create () {
      this.accountModal = true
    },
    handleAccountModal () {
      this.accountModal = false
    },
    handleAccount (account) {
      this.upsertAccount(account)
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadAccounts(this.serverParams)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          if (this.serverParams.forceOpen && items.length === 1) {
            this.onRowDblClick(items[0])
          }

          return { items, totalPages, page, totalItems }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRowDblClick (row) {
      this.$router.push(`/billing/accounts/entity/${row.id}`)
    },
    handleAddTransaction (account) {
      this.$refs.transactionModal.open({
        account: account,
        currency: account._embedded.rate,
        tax: (account._embedded.contractor && account._embedded.contractor.tax) || 0,
        state: 'confirmed'
      })
    },

  }
}
</script>
